import React, { Dispatch, SetStateAction, useEffect } from "react";
import User from "./User";
import { TrackState } from "../index";
import { useTrackWithFlags } from "../hooks/useTrackWithFlags";
import { useAuth } from "@localkitchens/passwordless-auth";

interface AppProps {
  setTrackingHandler: Dispatch<SetStateAction<TrackState>>;
}

const App = ({ setTrackingHandler }: AppProps) => {
  const { user, isAuthenticated } = useAuth();
  const { track } = useTrackWithFlags();

  useEffect(() => {
    setTrackingHandler({ track });
  }, [user, isAuthenticated]);

  return <User />;
};

export default App;
