import { Widget } from "@typeform/embed-react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTrackWithFlags } from "../../hooks/useTrackWithFlags";
import { Cart } from "../../types";

type MarketingSurveyProps = {
  isManager: boolean;
  cart: Cart;
  onFinish: () => void;
};

type TypeformWidgetProps = {
  cart: Cart;
};

const TypeformWidget = React.memo(({ cart }: TypeformWidgetProps) => {
  const { track } = useTrackWithFlags();
  const history = useHistory();

  let typeFormUrl = process.env.REACT_APP_TYPEFORM_URL;

  return (
    <Widget
      id={typeFormUrl ? typeFormUrl : ""}
      hideFooter={true}
      height={865}
      inlineOnMobile={true}
      hidden={{
        location: cart.location,
        provider: "kiosk",
      }}
      enableSandbox={process.env.NODE_ENV === "production" ? false : true}
      onSubmit={(e) => {
        track({
          event: "Submitted Typeform Marketing Attribution Survey kiosk",
          properties: {
            location: cart.location,
            provider: "kiosk",
          },
        });

        history.push("/thank-you");
      }}
    />
  );
});

export const MarketingSurvey = React.memo(
  ({ isManager, cart, onFinish }: MarketingSurveyProps) => {
    const history = useHistory();

    const onSkipClick = () => {
      history.push("/thank-you");
    };

    return (
      <div className="relative bg-lfg-light-gray h-screen flex items-center">
        <main className="mx-auto max-w-7xl px-4 mb-16 text-center">
          <TypeformWidget cart={cart} />
          <button
            className="text-2xl mt-6 px-16 py-4 border border-transparent font-lfg-book rounded-md text-lfg-light-gray bg-lfg-primary hover:bg-opacity-80"
            onClick={onSkipClick}
          >
            Skip
          </button>
        </main>
      </div>
    );
  },
);
