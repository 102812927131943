import { Terminal } from "@stripe/terminal-js";
import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Debug from "../components/Debug";
import { IdleModal } from "../components/IdleModal";
import { IdleTimer } from "../components/IdleTimer";
import Loading from "../components/Loading";
import Logout from "../components/Logout";
import UserContext from "../context/UserContext";
import { CreditCardController } from "../features/CreditCard";
import { DiningOption } from "../features/DiningOption";
import { EmailController } from "../features/Email";
import { MarketingSurvey } from "../features/MarketingSurvey";
import { MenuController } from "../features/Menu";
import {
  EditMenuItemController,
  MenuItemController,
} from "../features/MenuItem";
import { Name } from "../features/Name";
import { PhoneNumber } from "../features/PhoneNumber";
import { SettingsController } from "../features/Settings";
import { ThankYou } from "../features/ThankYou";
import { TipController } from "../features/Tip";
import { useLocationAccount } from "../hooks/useLocationAccount";
import { useLocationInfo } from "../hooks/useLocationInfo";
import { Brands, Cart, ItemsMap, LocationAccount, Menu } from "../types";
import LocationChooserController from "../features/Location/LocationChooserController";

type RoutesProps = {
  isManager: boolean;
  isIdleModalOpen: boolean;
  itemsMap: ItemsMap;
  terminal: Terminal;
  brands: Brands;
  menus: Menu[];
  cart: Cart;
  onCartChange: (cart: Cart) => void;
  onMenuItemRemove: (externalId: string) => void;
  onMenuItemEdit: (itemId: number, externalId: string) => void;
  onMenuItemAdd: (cart: Cart, externalId?: string) => void;
  onMenuItemUpdate: (cart: Cart, externalId?: string) => void;
  logout: () => void;
  clearCart: () => void;
  onFinish: () => void;
  setIsIdle: () => void;
  setNotIdle: () => void;
};

const Routes = ({
  isManager,
  logout,
  terminal,
  itemsMap,
  setIsIdle,
  isIdleModalOpen,
  menus,
  brands,
  cart,
  onCartChange,
  onMenuItemEdit,
  onMenuItemRemove,
  onMenuItemAdd,
  onMenuItemUpdate,
  clearCart,
  onFinish,
  setNotIdle,
}: RoutesProps) => {
  const location = useLocation();
  const { userEmail } = useContext(UserContext);
  const { data: locationAccountData } = useLocationAccount(userEmail);
  const locationSlug = locationAccountData?.location_account?.location?.slug;

  // Info about specific location (i.e. tax rate)
  const { data: locationInfoData } = useLocationInfo(locationSlug);

  return (
    <>
      <Switch>
        <IdleTimer
          onIdle={() => {
            if (location.pathname !== "/") {
              setIsIdle();
            }
          }}
        >
          <Route path="/menu/:menuId?">
            <MenuController
              isManager={isManager}
              menus={menus}
              brands={brands}
              cart={cart}
              onCartChange={onCartChange}
              onMenuItemEdit={onMenuItemEdit}
              onMenuItemRemove={onMenuItemRemove}
              onMenuItemAdd={onMenuItemAdd}
              onMenuItemUpdate={onMenuItemUpdate}
              clearCart={clearCart}
            />
          </Route>

          <Route exact path="/menu-item/:itemId/edit/:externalId">
            <EditMenuItemController
              itemsMap={itemsMap}
              brands={brands}
              collections={menus[0].collections}
              cart={cart}
              onCartChange={onCartChange}
              onMenuItemEdit={onMenuItemEdit}
              onMenuItemRemove={onMenuItemRemove}
              onMenuItemAdd={onMenuItemAdd}
              onMenuItemUpdate={onMenuItemUpdate}
              clearCart={clearCart}
            />
          </Route>

          <Route exact path="/menu-item/:itemId">
            <MenuItemController
              itemsMap={itemsMap}
              collections={menus[0].collections}
              brands={brands}
              cart={cart}
              onCartChange={onCartChange}
              onMenuItemEdit={onMenuItemEdit}
              onMenuItemRemove={onMenuItemRemove}
              onMenuItemAdd={onMenuItemAdd}
              onMenuItemUpdate={onMenuItemUpdate}
              clearCart={clearCart}
            />
          </Route>

          <Route path="/name">
            <Name
              menus={menus}
              isManager={isManager}
              cart={cart}
              onCartChange={onCartChange}
            />
          </Route>

          <Route path="/phone-number">
            <PhoneNumber
              isManager={isManager}
              cart={cart}
              onCartChange={onCartChange}
            />
          </Route>

          <Route path="/email">
            <EmailController
              cart={cart}
              onCartChange={onCartChange}
              onFinish={onFinish}
            />
          </Route>

          <Route exact path="/tip">
            <TipController
              isManager={isManager}
              cart={cart}
              onCartChange={onCartChange}
            />
          </Route>

          <Route exact path="/credit-card">
            {locationInfoData?.store_location ? (
              <CreditCardController
                isManager={isManager}
                cart={cart}
                onCartChange={onCartChange}
                terminal={terminal}
                storeLocation={locationInfoData?.store_location}
              />
            ) : (
              <Loading />
            )}
          </Route>

          <Route exact path="/thank-you">
            <ThankYou isManager={isManager} cart={cart} onFinish={onFinish} />
          </Route>

          <Route exact path="/marketing-survey">
            <MarketingSurvey
              isManager={isManager}
              cart={cart}
              onFinish={onFinish}
            />
          </Route>

          <Route exact path="/settings">
            <SettingsController terminal={terminal} onLogOut={logout} />
          </Route>

          <Route path="/locations">
            <LocationChooserController
              isManager={isManager}
              locationAccount={
                locationAccountData?.location_account as LocationAccount
              }
            />
          </Route>

          <Route exact path="/logout">
            <Logout onLogOut={logout} />
          </Route>

          <Route exact path="/debug">
            <Debug />
          </Route>

          <Route path="/dining-option">
            <DiningOption
              menus={menus}
              cart={cart}
              onCartChange={onCartChange}
            />
          </Route>
        </IdleTimer>
      </Switch>

      {isIdleModalOpen && (
        <IdleModal
          onFinish={() => (window.location.href = "/")}
          onClose={setNotIdle}
        />
      )}
    </>
  );
};

export default Routes;
