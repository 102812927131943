import { PasswordlessAuth } from "@localkitchens/passwordless-auth";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";
import ErrorBoundary from "./containers/ErrorBoundary";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ApolloWrapper } from "./utils/ApolloWrapper";
import "./utils/polyfills";

const {
  REACT_APP_AUTH_ORIGIN = "",
  REACT_APP_GOOGLE_OATH_CLIENT_ID = "",
  NODE_ENV = "",
  REACT_APP_RELEASE = "",
  REACT_APP_SENTRY_DSN = "",
} = process.env;

const { identify } = window.analytics;

type TrackingEvent = {
  event: string;
  properties?: object;
};

type TrackState = {
  track: TrackingHandler;
};
type TrackingHandler = (trackingEvent: TrackingEvent) => void;

const track = () => {};

if (NODE_ENV === "production") {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    autoSessionTracking: true,
    release: REACT_APP_RELEASE,
    environment: NODE_ENV,
  });
}

const AppWrapper = () => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [tracker, setTrackingHandler] = useState<TrackState>({ track });

  const trackingHandler = useMemo(() => tracker.track, [tracker]);

  useEffect(() => {
    if (
      !window.location.href.includes(".com") &&
      !window.location.href.includes(".kitchen") &&
      !window.location.href.includes(".dev")
    ) {
      const url = window.location.href.replace(".co", ".com");
      window.location.href = url;
    }
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <PasswordlessAuth
          authOrigin={REACT_APP_AUTH_ORIGIN}
          googleClientId={REACT_APP_GOOGLE_OATH_CLIENT_ID}
          track={trackingHandler}
          identify={identify}
        >
          <ApolloWrapper>
            <App setTrackingHandler={setTrackingHandler} />
          </ApolloWrapper>
        </PasswordlessAuth>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type { TrackingHandler, TrackState, TrackingEvent };
